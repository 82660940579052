@import "./images";
@import "./variables";
@import "./listItems";

body {
  background-color: --color_blanco;
}
.container-class {
  min-height: 30rem;
}

.btn-modal {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;

  & button {
    width: 70px;
  }
}

@media screen and (max-width: 480px) {
  body {
    font-size: 80%; /** achicamos la fuente en pantallas pequeñas **/
    background-color: --color_blanco;
  }

  #toolbar-display {
    display: none;
  }

  .toolbar-title {
    display: none;
  }

  .nav {
    display: flex;
    flex-direction: column;
  }

  .btn-web {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: color_boton_primario;
  }

  .nav-item {
    width: 83px;
  }
  .btn-dashboard {
    width: 100%; /**revisar **/
    margin-bottom: 10px;
  }

  div.scroll_horizontal {
    overflow-x: auto;
  }

  .contenedor-detalle {
    padding: 1.5rem;
  }
}
