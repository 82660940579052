
.boton{
  background-color: var(--color_fuentePrincipal);
}

nav .nav-pills .nav-item li a{
  color: rgb(126, 36, 36) !important;
  }

.bg-primario{
  background-color: var(--color_primario);
  color: var(--color_letra);
}
.bg-blanco{
  background-color: var(--color_blanco);
  color: var(--color_primario);
}

.container-imagenes{
    padding: 0%;
    max-width: 100%;
}

.container-4:hover button.icon, .container-4:active button.icon, .container-4:focus button.icon{
    outline: none;
    opacity: 1;
    margin-left: -50px;
  }
 
  .container-4:hover button.icon:hover{
    background: white;
  }

  
.card-imagen:hover {
    //border: var(--color_fuentePrincipal) 1px solid;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);;
}

  .p-2{
      display: -webkit-flex;
      display: flex;
  }

  .cardFooter{
    padding: .75rem 0.25rem;
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,.125);
}
  .card-footer {
    padding: 0.75rem 0.25rem;
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,55, 255,.125);
}
.font-negrilla{
    font-weight: 700;
    color: --color_primario;
    margin-bottom: 0.5rem;
    line-height: 1.2;
}
.font-normal {
    font-weight: 400;
    color: #212529;
}
.font-normal-verdadero {
    font-weight: 500;
    color: green;
}
.font-normal-sinprocess{
  font-weight: 500;
  color: rgb(233, 133, 2);
}
.font-normal-falso {
    font-weight: 500;
    color: red;
}
.font-grande{
  font-size: larger;
}
.font-grande-falso{
  font-size: larger;
  color: red;
}
.font-grande-verdadero{
  font-size: larger;
  color: green;
}

  .col-temporada{
    border-radius: 7px;
    height: 55px;
    color: 85C1E9;
  }
  .col-sm-4{
    padding-bottom: 10px;
  }
  /**

  */

  .react-dropdown-select-input {
    display: none;
  }
  .col-busqueda{
    display: flex;
    flex: 100%;
    max-width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  
  .container-buscador{
      display: flex;
  }

  .detalleCard{
    background-color: var(--color_primario);
    width: 100%;;
  }

  .contenedor-imagen{
      position: relative;
  }
  .imagen-respuesta{
    position: absolute;
    bottom: 5px;
    left: 5px;
  }
  
  .card-imagenes{
    max-width: 33%;
    flex-basis: 50%;
    flex-wrap: wrap;
    
    .selector{
        color: 85C1E9;
        border-radius: 7px;
        height: 100%;
        border-radius: 7px;
        height: 55px;
        width: 300px
    }
  }

  .detalle{
    border: --color_fuentePrincipal 1px solid;
    border-radius: 0.25rem;
    &:hover {
        box-shadow: 0px 10px 10px --color_fuentePrincipal;
    }

  }

 
