.canvas-container {
    width: 100%;
    max-width: 1024px; /* Establece un ancho máximo opcional */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.canvas-container canvas {
    display: block;
    width: 100%;
    height: auto;
}