.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;

    img{
        width: 90px;
        height: 18px ;
        margin: 1em;
    }
}